import { AxiosResponse } from "axios";
import axios from "../axios/axios";
import {
  BookedTimeResponse,
  BookingByUserIdType,
  BookingItemResponse,
} from "./types";

let config = {
  headers: {
    accept: "*/*",
    "Content-Type": "application/json",
  },
};

const endpoints = {
  getBookedTime: (data: string): Promise<AxiosResponse<BookedTimeResponse>> => {
    const response = axios.get(`/dexsys-bot/v1/bookings?${data}`, config);
    return response;
  },
  setBookTime: (data: string): Promise<AxiosResponse<BookingItemResponse>> => {
    const response = axios.post(
      `/dexsys-bot/v1/bookings/booking`,
      data,
      config
    );
    return response;
  },
  getBookingByUserId: (
    data: string
  ): Promise<AxiosResponse<BookingByUserIdType>> => {
    const response = axios.get(`/dexsys-bot/v1/bookings/users/${data}`, config);
    return response;
  },
  deleteBookingById: (id: string): Promise<AxiosResponse> => {
    const response = axios.delete(`/dexsys-bot/v1/bookings/${id}`, config);
    return response;
  },
};

export default endpoints;

import axios, { AxiosRequestConfig } from "axios";
import { getAccessToken } from "../utils/getAccessToken";

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const authToken = getAccessToken();
    if (authToken && config.headers) {
      config.headers.authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;

import { AxiosResponse } from "axios";
import axios from "../axios/axios";
import { LoginResponse } from "./types";

const config = {
  headers: {
    accept: "*/*",
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const endpoints = {
  login: (data: string): Promise<AxiosResponse<LoginResponse>> => {
    const response = axios.post(`/login`, data, config);
    return response;
  },
};

export default endpoints;

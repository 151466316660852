export const toggleClass = (
  booked: boolean,
  index: number,
  selectedButtons: number[]
): boolean => {
  if (selectedButtons.length > 1 && !booked) {
    if (
      index >= Number(selectedButtons[0]) &&
      index <= Number(selectedButtons[1])
    ) {
      return true;
    }
  }
  if (index === Number(selectedButtons[0])) {
    return true;
  }
  return false;
};

import React, { useEffect, useState } from "react";
import CustomScroll from "react-custom-scroll";

import api from "../../../../api";
import { formatMyBookingDate } from "./utils/formatMyBookingDate";
import { formatMyBookingTime } from "./utils/formatMyBookingTime";
import { Basket } from "./icons";
import { BookingByUserIdType } from "../../../../api/endpoints/types";

import styles from "./styles.module.scss";
import { UserBookingsSkeleton } from "./UserBookingsSkeleton";

interface UserBookingsProps {
  disabled: boolean;
}

export const UserBookings: React.FC<UserBookingsProps> = ({ disabled }) => {
  const [bookingsListArr, setbookingsListArr] =
    useState<BookingByUserIdType | null>(null);

  const getUserBookigs = async () => {
    const userID = sessionStorage.getItem("telegramId") ?? "";
    setbookingsListArr(null);
    try {
      const bookingsList = await api.booking.getBookingByUserId(userID);
      setbookingsListArr([...bookingsList.data]);
    } catch (err: any) {
      if (err.message === "Request failed with status code 403") {
        localStorage.clear();
        window.location.reload();
      }
    }
  };

  const deleteBooking = async ({
    currentTarget,
  }: React.MouseEvent<HTMLButtonElement>) => {
    try {
      await api.booking.deleteBookingById(currentTarget.id);
      getUserBookigs();
    } catch (err: any) {
      if (err.message === "Request failed with status code 403") {
        localStorage.clear();
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    getUserBookigs();
  }, []);

  if (!bookingsListArr) {
    return <UserBookingsSkeleton />;
  }

  return (
    <div className={styles.Wrapper}>
      <div className={styles.ContentWrapper}>
        <div className={styles.TitleWrapper}>
          <p className={styles.Title}>Мои бронирования</p>
          <button
            className={styles.Button}
            onClick={getUserBookigs}
            disabled={disabled}
          >
            Обновить
          </button>
        </div>
        <div className={styles.TableWrapper}>
          <CustomScroll allowOuterScroll={true} heightRelativeToParent="100%">
            <table className={styles.Table}>
              <thead className={styles.TableHead}>
                <tr>
                  <th>Дата</th>
                  <th>Время</th>
                  <th>Место</th>
                  <th>Удалить</th>
                </tr>
              </thead>
              <tbody>
                {bookingsListArr.length !== 0 &&
                  bookingsListArr.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>{formatMyBookingDate(item.date ?? "-")}</td>
                        <td>{`${formatMyBookingTime(
                          item.interval.from ?? "-"
                        )} - ${formatMyBookingTime(
                          item.interval.to ?? "-"
                        )}`}</td>
                        <td>№ {item.place}</td>
                        <td>
                          <button
                            id={item.id}
                            className={styles.DeleteButton}
                            onClick={deleteBooking}
                            disabled={disabled}
                          >
                            <img
                              src={Basket}
                              alt=""
                              width={32}
                              height={32}
                              className={styles.InfoItemIcon}
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </CustomScroll>
        </div>
      </div>
    </div>
  );
};

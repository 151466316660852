import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { useEffect } from "react";
import axios, { AxiosResponse } from "axios";

import { AppConfig } from "./context/types";
import axiosInstance from "./api/axios/axios";
import BookingPage from "./pages/BookingPage/BookingPage";
import { AuthPage } from "./pages/AuthPage/AuthPage";

import "./App.css";

function App() {
  useEffect(() => {
    axios
      .get(`/config/environment.json`)
      .then(({ data: remoteConfig }: AxiosResponse<AppConfig>) => {
        axiosInstance.defaults.baseURL = remoteConfig?.bot?.baseURL;
      });
  });

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/auth" component={AuthPage} />
          <Route path="/booking" component={BookingPage} />
          <Redirect from="/" to="/auth" />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

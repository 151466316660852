import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import cn from "classnames";

import { SkeletonSizeType } from "../UserBookings/types";
import { getWorkPlacesTimeSkeletonSize } from "./utils";

import "react-loading-skeleton/dist/skeleton.css";
import styles from "./styles.module.scss";

export const WorkPlacesTimeSkeleton: React.FC = () => {
  const clientWidth = window.innerWidth;

  const [size, setSize] = useState<SkeletonSizeType>(
    getWorkPlacesTimeSkeletonSize(clientWidth)
  );

  useEffect(() => {
    setSize(getWorkPlacesTimeSkeletonSize(clientWidth));
  }, [clientWidth]);

  return (
    <div className={cn(styles.WorkTimeWrapper, styles.WorkTimeWrapperSkeleton)}>
      <Skeleton height={size.heigth} width={size.width} />
    </div>
  );
};

import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { SkeletonSizeType } from "../UserBookings/types";

import { getBookingMapSkeletonSize } from "./utils/getBookingMapSkeletonSize";

import "react-loading-skeleton/dist/skeleton.css";
import styles from "./styles.module.css";

export const BookingMapSkeleton: React.FC = () => {
  const clientWidth = window.innerWidth;

  const [size, setSize] = useState<SkeletonSizeType>(
    getBookingMapSkeletonSize(clientWidth)
  );

  useEffect(() => {
    setSize(getBookingMapSkeletonSize(clientWidth));
  }, [clientWidth]);

  return (
    <div className={styles.Wrapper}>
      <Skeleton height={size.heigth} width={size.width} />
    </div>
  );
};

export const REGEXP = {
  Login: /^\d+$/gm,
  Password: /^\b[0-9A-Za-z]{20}$/gm,
};

export const ERROR_MESSAGES = {
  InvalidLogin: "Поле может содержать только цифры от 0 до 9",
  EmptyField: "Поле не должно быть пустым",
  InvalidPassword: "Поле должно содержать 20 символов, латинские буквы и цифры",
};

import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import Calendar, { DateCallback } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useForm } from "react-hook-form";

import api from "../../../../api";
import { BookedTimeResponse } from "../../../../api/endpoints/types";
import {
  selectedButtonsType,
  selectedTimesType,
  WorkPlacesTimeItem,
} from "./types";
import {
  toggleClass,
  setBookTimeData,
  formatDate,
  getInitialDate,
  getBookedsTime,
} from "./utils";
import { BookingMapEnum } from "../../types";
import {
  WorkPlacesQuiet,
  WorkPlacesStandart,
  WorkPlacesTimeDescription,
} from "./constant";

import styles from "./styles.module.scss";
import { WorkPlacesTimeSkeleton } from "./WorkPlacesTimeSkeleton";

interface BookingFormProps {
  disabled: boolean;
  type: BookingMapEnum;
}

export const BookingForm: React.FC<BookingFormProps> = ({ disabled, type }) => {
  const { minDate, maxDate, defaultDate, initialDate } = getInitialDate();

  const WorkPlacesArr =
    type === BookingMapEnum.standart ? WorkPlacesStandart : WorkPlacesQuiet;

  const [calendarValue, setCalendarValue] = useState(defaultDate);

  const [workPlacesTimeArr, setWorkPlacesTimeArr] =
    useState<BookedTimeResponse | null>([]);

  const [timeError, setTimeError] = useState(false);

  const [bookingError, setbookingError] = useState(false);

  const [isAccesBooking, setIsAccesBooking] = useState(false);

  const [workingPlace, setWorkingPlace] = useState(
    type === BookingMapEnum.standart ? "1" : "8"
  );

  const [selectedButtons, setSelectedButtons] = useState<selectedButtonsType>(
    []
  );

  const [selectedTimes, setSelectedTimes] = useState<selectedTimesType>([]);

  const [dataIsLoaded, setdataIsLoaded] = useState(false);

  const resetInfoMessages = () => {
    setbookingError(false);
    setIsAccesBooking(false);
    setTimeError(false);
  };

  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    const userID = sessionStorage.getItem("telegramId") ?? "";
    const BookTimeData = setBookTimeData(
      workingPlace,
      userID,
      selectedTimes,
      calendarValue
    );

    try {
      await api.booking.setBookTime(JSON.stringify(BookTimeData));
      setIsAccesBooking(true);
    } catch (err: any) {
      if (err.message === "Request failed with status code 406") {
        resetInfoMessages();
        setbookingError(true);
      }
    }
  };

  const getBookedTimeRest = async (
    currentTarget?: EventTarget & HTMLInputElement,
    workingPlace?: string
  ) => {
    if (calendarValue > initialDate) {
      setWorkPlacesTimeArr(null);
      try {
        const bookedListResponse = await api.booking.getBookedTime(
          `date=${formatDate(calendarValue, "YYYY-MM-DD")}&placeName=${
            currentTarget?.id ?? workingPlace
          }`
        );
        setWorkPlacesTimeArr([...bookedListResponse.data]);
      } catch (err: any) {
        if (err.message === "Request failed with status code 403") {
          //Необходимо выпилить
          localStorage.clear();
          window.location.reload();
        }
      }
    }
    return;
  };

  const updateButtonOnclick = () => {
    getBookedTimeRest(undefined, workingPlace);
    resetButtonOnclick();
  };

  const handleClick = async ({
    currentTarget,
  }: React.MouseEvent<HTMLInputElement>) => {
    resetInfoMessages();
    setWorkingPlace(`${currentTarget.id}`);
    getBookedTimeRest(currentTarget);
  };

  const selectTime = ({
    currentTarget,
  }: React.MouseEvent<HTMLInputElement>) => {
    const buttonId = Number(currentTarget.dataset.id);
    const value = currentTarget.value;
    if (!selectedButtons.length) {
      setSelectedButtons([buttonId]);
      setSelectedTimes([value]);
    }
    if (selectedButtons.length === 1) {
      if (buttonId > selectedButtons[0]) {
        setSelectedButtons([...selectedButtons, buttonId]);
        setSelectedTimes([...selectedTimes, value]);
      }
      if (buttonId < selectedButtons[0]) {
        setSelectedButtons([buttonId, ...selectedButtons]);
        setSelectedTimes([value, ...selectedTimes]);
      }
      if (buttonId === selectedButtons[0]) {
        setSelectedButtons([]);
        setSelectedTimes([]);
        setTimeError(false);
      }
    }
    if (selectedButtons.length === 2) {
      if (buttonId === selectedButtons[0]) {
        setSelectedButtons([]);
        setSelectedTimes([]);
        setTimeError(false);
      }
      if (buttonId === selectedButtons[1]) {
        setSelectedButtons([selectedButtons[0]]);
        setSelectedTimes([selectedTimes[0]]);
      }
      if (
        buttonId > selectedButtons[1] ||
        (buttonId < selectedButtons[1] && buttonId > selectedButtons[0])
      ) {
        setSelectedButtons([selectedButtons[0], buttonId]);
        setSelectedTimes([selectedTimes[0], value]);
      }
      if (buttonId < selectedButtons[0]) {
        setSelectedButtons([buttonId, selectedButtons[1]]);
        setSelectedTimes([value, selectedTimes[1]]);
      }
    }
  };

  useEffect(() => {
    setSelectedButtons([]);
    setSelectedTimes([]);
  }, [workingPlace]);

  useEffect(() => {
    resetButtonOnclick();
    resetInfoMessages();
    setWorkingPlace(type === BookingMapEnum.standart ? "1" : "8");
  }, [type]);

  useEffect(() => {
    setdataIsLoaded(false);
  }, [type]);

  const onClickDay: DateCallback = async (value) => {
    resetInfoMessages();
    setCalendarValue(value);
    dataIsLoaded ? console.log("Бронь загружена") : setdataIsLoaded(true);
    setWorkPlacesTimeArr(null);
    try {
      const bookedListResponse = await api.booking.getBookedTime(
        `date=${formatDate(value, "YYYY-MM-DD")}&placeName=${workingPlace}`
      );
      setWorkPlacesTimeArr([...bookedListResponse.data]);
    } catch (err: any) {
      if (err.message === "Request failed with status code 403") {
        sessionStorage.clear();
        window.location.reload();
      }
    }
    setSelectedButtons([]);
    setSelectedTimes([]);
  };

  const resetButtonOnclick = () => {
    setSelectedButtons([]);
    setSelectedTimes([]);
    resetInfoMessages();
  };

  const WorkPlacesTimeArr = getBookedsTime(
    WorkPlacesTimeDescription,
    workPlacesTimeArr
  );

  return (
    <form className={styles.BookingForm} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.WorkPlacesWrapper}>
        <p className={styles.WorkPlacesTitle}>Выберите рабочее место</p>
        <div className={styles.WorkPlacesButtonWrapper}>
          {WorkPlacesArr.map((item) => {
            return (
              <input
                type="button"
                defaultValue={item.id}
                key={item.id}
                id={item.id}
                onClick={handleClick}
                disabled={disabled}
                className={cn(styles.WorkPlacesButton, {
                  [styles.WorkPlacesButton_Active]: workingPlace === item.id,
                })}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.DateWrapper}>
        <label htmlFor="date" className={styles.DateLabel}>
          Выберите дату
        </label>
        <Calendar
          className={styles.DateCalendar}
          onClickDay={onClickDay}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
      {dataIsLoaded &&
        (WorkPlacesTimeArr ? (
          <div className={styles.WorkTimeWrapper}>
            <div className={styles.WorkTimeTitle}>
              <p>Выберите время</p>
              <div className={styles.UpdateButtonWrapper}>
                <button
                  type="button"
                  className={styles.UpdateButton}
                  onClick={updateButtonOnclick}
                  disabled={disabled}
                >
                  Обновить
                </button>
                <button
                  type="button"
                  className={styles.ResetButton}
                  onClick={resetButtonOnclick}
                  disabled={disabled}
                >
                  Сбросить
                </button>
              </div>
            </div>
            <div className={styles.WorkTimeButtonsWrapper}>
              {WorkPlacesTimeArr &&
                WorkPlacesTimeArr.map(
                  (item: WorkPlacesTimeItem, index: number) => {
                    if (
                      item.booked.booked &&
                      index < selectedButtons[1] &&
                      index > selectedButtons[0] &&
                      !timeError
                    ) {
                      setTimeError(true);
                    }
                    return (
                      <input
                        type="button"
                        value={item.time}
                        data-id={index}
                        className={cn(styles.WorkTimeButton, {
                          [styles.WorkTimeButton_Booked]: item.booked.booked,
                          [styles.WorkTimeButton_Select]: toggleClass(
                            item.booked.booked,
                            index,
                            selectedButtons
                          ),
                        })}
                        key={item.time}
                        onClick={selectTime}
                        disabled={item.booked.booked || disabled}
                      />
                    );
                  }
                )}
            </div>
            <div className={styles.MessageContainer}>
              {timeError && (
                <p className={styles.Error}>
                  Выбранное Вами время пересекается с другим пользователем,
                  пожалуйста, выберите другое время.
                </p>
              )}
              {bookingError && (
                <p className={styles.Error}>
                  Выбранное Вами время уже забронировано другим пользователем,
                  пожалуйста выберите другую дату и обновите свободные слоты
                </p>
              )}
              {isAccesBooking && (
                <p className={styles.Acces}>
                  Время успешно забронировано! Обновите таблицу выбора времени.
                </p>
              )}
            </div>
            <div className={styles.BookingButtonWrapper}>
              <button
                className={styles.BookingButton}
                type="submit"
                disabled={
                  (!(selectedButtons.length === 2) ||
                    timeError ||
                    bookingError ||
                    disabled) &&
                  true
                }
              >
                Забронировать
              </button>
            </div>
          </div>
        ) : (
          <WorkPlacesTimeSkeleton />
        ))}
    </form>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTimer } from "react-timer-hook";
import cn from "classnames";

import { BookingMap } from "./components/BookingMap/BookingMap";
import { DexSysLogo } from "../../components/DexSysLogo/DexSysLogo";
import { BookingForm } from "./components/BookingForm/BookingForm";
import { getAccessToken } from "../../api/utils/getAccessToken";
import { UserBookings } from "./components/UserBookings/UserBookings";
import { BookingComponentsEnum, BookingMapEnum } from "./types";
import { getExpired } from "./utils/getExpired";

import styles from "./styles.module.scss";

const BookingPage: React.FC = () => {
  const [bookingComponent, setbookingComponent] =
    useState<BookingComponentsEnum>(BookingComponentsEnum.bookingForm);

  const [bookingMap, setbookingMap] = useState<BookingMapEnum>(
    BookingMapEnum.quiet
  );

  const [popoverTimeOut, setpopoverTimeOut] = useState(false);

  const history = useHistory();

  const token = getAccessToken();

  const expired = getExpired();

  //Запуск таймера. Время вычисляется из expire внутри токена.  По истечению таймера выходит сообщение о необходимости login.

  const time: Date | null = useMemo(
    () => (expired ? new Date(expired * 1000) : null),
    [expired]
  );

  useEffect(() => {
    if (!time) {
      setpopoverTimeOut(true);
    }
  }, [time]);

  //Вызов окна с информацией об истечении токена.
  const timeOut = () => {
    console.log("время токена истекло");
    setpopoverTimeOut(true);
  };

  function MyTimer({ expiryTimestamp }: any) {
    useTimer({
      expiryTimestamp,
      onExpire: () => timeOut(),
    });
    return <div style={{ display: "none" }}></div>;
  }

  if (!token) {
    return (
      <div className={styles.Wrapper}>
        <div>
          <p>
            Отсутствует токен авторизации...
            <Link to="/auth">Login</Link>
          </p>
          <p>
            Если Вы видите данное сообщение после определенного действия, значит
            произошла непредвиденная ошибка. Просьба обратиться к службе
            поддержки приложения
          </p>
        </div>
      </div>
    );
  }

  const onClick = ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
    currentTarget.id === BookingComponentsEnum.bookingForm
      ? setbookingComponent(BookingComponentsEnum.bookingForm)
      : setbookingComponent(BookingComponentsEnum.userBookings);
  };

  // const selectSpaceonClick = ({
  //   currentTarget,
  // }: React.MouseEvent<HTMLButtonElement>) => {
  //   currentTarget.id === "standart"
  //     ? setbookingMap(BookingMapEnum.standart)
  //     : setbookingMap(BookingMapEnum.quiet);
  // };

  const logOut = () => {
    sessionStorage.clear();
    history.push("/auth");
  };

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Logo}>
        <DexSysLogo />
        <MyTimer expiryTimestamp={time} autoStart={true} onExpire={timeOut} />
      </div>
      <div className={styles.BookingMapWrapper}>
        {/* Временно убран по просьбе HR. Остается только тихий коворк */}
        {/* <div className={styles.ButtonWrapper}>

          <button
            onClick={selectSpaceonClick}
            id={BookingMapEnum.standart}
            className={cn(styles.Button, {
              [styles.Button_Active]: bookingMap === BookingMapEnum.standart,
            })}
            disabled={popoverTimeOut}
          >
            Стандартный коворкинг
          </button>
          <button
            onClick={selectSpaceonClick}
            id={BookingMapEnum.quiet}
            className={cn(styles.Button, {
              [styles.Button_Active]: bookingMap === BookingMapEnum.quiet,
            })}
            disabled={popoverTimeOut}
          >
            Тихий коворкинг
          </button>
        </div> */}
        <BookingMap type={bookingMap} />
      </div>
      <div className={styles.BookingWrapper}>
        <div className={styles.ButtonWrapper}>
          <button
            onClick={onClick}
            id={BookingComponentsEnum.bookingForm}
            className={cn(styles.Button, {
              [styles.Button_Active]:
                bookingComponent === BookingComponentsEnum.bookingForm,
            })}
            disabled={popoverTimeOut}
          >
            Форма бронирования
          </button>
          <button
            onClick={onClick}
            id={BookingComponentsEnum.userBookings}
            className={cn(styles.Button, {
              [styles.Button_Active]:
                bookingComponent === BookingComponentsEnum.userBookings,
            })}
            disabled={popoverTimeOut}
          >
            Мои бронирования
          </button>
        </div>
        {bookingComponent === BookingComponentsEnum.bookingForm && (
          <BookingForm disabled={popoverTimeOut} type={bookingMap} />
        )}
        {bookingComponent === BookingComponentsEnum.userBookings && (
          <UserBookings disabled={popoverTimeOut} />
        )}
      </div>
      {popoverTimeOut && (
        <div className={styles.PopoverWrapper}>
          <div className={styles.PopoverTimeOut}>
            <div>
              <p>Время действия временного пароля истекло.</p>
              <p>
                Пожалуйста, выйдите из приложения, запросите новый пароль и
                выполните вход
              </p>
            </div>
            <div>
              <button className={styles.LogoutButton} onClick={logOut}>
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingPage;

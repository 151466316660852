export const getInitialDate = () => {
  const minDate = new Date();
  const maxDate = new Date();

  const defaultDate = new Date();

  const initialDate = new Date();

  initialDate.setDate(defaultDate.getDate() - 1);

  maxDate.setDate(maxDate.getDate() + 14);

  defaultDate.setDate(defaultDate.getDate() - 2);
  return { minDate, maxDate, defaultDate, initialDate };
};

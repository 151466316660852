import { BookTimeDataType, selectedTimesType } from "../types";
import { formatDate } from "./formatDate";
import { formatTime } from "./formatTime";

export const setBookTimeData = (
  workingPlace: string,
  userID: string,
  selectedTimes: selectedTimesType,
  calendarValue: Date
): BookTimeDataType => {
  return {
    place: workingPlace,
    userTelegramId: Number(userID),
    date: formatDate(calendarValue, "YYYY-MM-DD"),
    timeFrom: formatTime(selectedTimes[0] ?? "-"),
    timeTo: formatTime(selectedTimes[1] ?? "-"),
  };
};

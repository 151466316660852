export type TokenType = {
  sub: string;
  roles: string[];
  iss: string;
  exp: number;
};

export enum BookingComponentsEnum {
  bookingForm = "BookingForm",
  userBookings = "UserBookings",
}

export enum BookingMapEnum {
  quiet = "quiet",
  standart = "standart",
}

export type infoDescriptionType = {
  quiet: infoDescriptionItem;
  standart: infoDescriptionItem;
};

type infoDescriptionItem = {
  title: string;
  description: string;
};

import { SkeletonSizeType } from "../../UserBookings/types";

export const getBookingMapSkeletonSize = (
  clientWidth: number | null
): SkeletonSizeType => {
  if (clientWidth) {
    if (clientWidth > 1845) {
      return { width: 675, heigth: 700 };
    }
    if (clientWidth > 970 && clientWidth <= 1845) {
      return { width: 600, heigth: 600 };
    }
    if (clientWidth <= 970 && clientWidth > 840) {
      return { width: clientWidth - 300, heigth: 600 };
    }

    if (clientWidth <= 840 && clientWidth > 400) {
      return { width: clientWidth - 100, heigth: clientWidth - 60 };
    }

    if (clientWidth <= 400) {
      return { width: clientWidth - 100, heigth: clientWidth - 60 };
    }
  }
  return { width: 0, heigth: 0 };
};

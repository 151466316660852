import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import styles from "./styles.module.scss";
import { SkeletonSizeType } from "./types";
import { getUserBookingsSkeletonSize } from "./utils/getUserBookingsSkeletonSize";

export const UserBookingsSkeleton: React.FC = () => {
  const clientWidth = window.innerWidth;

  const [size, setSize] = useState<SkeletonSizeType>(
    getUserBookingsSkeletonSize(clientWidth)
  );
  useEffect(() => {
    setSize(getUserBookingsSkeletonSize(clientWidth));
  }, []);

  return (
    <div className={styles.Wrapper}>
      <Skeleton height={size.heigth} width={size.width} />
    </div>
  );
};

import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import { DexSysLogo } from "../../components/DexSysLogo/DexSysLogo";
import { setAccessToken } from "../../api/utils/setAccessToken";
import { getAccessToken } from "../../api/utils/getAccessToken";
import { validateLogin, validatePassword } from "./validations";
import { ERROR_MESSAGES } from "./constants";

import api from "../../api";

import styles from "./styles.module.scss";

type Inputs = {
  login: string;
  password: string;
};

export const AuthPage: React.FC = () => {
  const [isError, setIsError] = useState(false);

  const history = useHistory();

  const location = useLocation();

  const queryString = require("query-string");

  const query = queryString.parse(location.search);

  const { login = null } = query;

  const token = getAccessToken();

  if (token) {
    history.push("/booking");
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({ mode: "all" });

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const loginData = await api.auth.login(
        `login=${data.login}&password=${data.password}`
      );
      if (loginData.status === 401) {
        throw Error("Обновите временный пароль или проверьте логин");
      }
      setAccessToken(loginData.data.accessToken);
      sessionStorage.setItem("telegramId", loginData.data.telegramId);
      setIsError(false);
    } catch (error: any) {
      setIsError(true);
    }
  };

  return (
    <div className={styles.Wrapper}>
      <form className={styles.AuthForm} onSubmit={handleSubmit(onSubmit)}>
        <h1 className={styles.AuthFormTitle}>Введите параметры входа</h1>
        <div className={styles.InputWrapper}>
          <label htmlFor="login" className={styles.AuthFormLabel}>
            Логин
          </label>
          <input
            type="text"
            className={styles.AuthFormInput}
            defaultValue={login}
            {...register("login", {
              required: ERROR_MESSAGES.EmptyField,
              validate: validateLogin,
            })}
          />
          <div className={styles.LoginErrorsWrapper}>
            {errors.login && (
              <span className={styles.Error}>{errors.login.message}</span>
            )}
          </div>
        </div>
        <div className={styles.InputWrapper}>
          <label htmlFor="password" className={styles.AuthFormLabel}>
            Пароль
          </label>
          <input
            type="password"
            {...register("password", {
              required: ERROR_MESSAGES.EmptyField,
              validate: validatePassword,
            })}
            className={styles.AuthFormInput}
          />
          <div className={styles.PasswordErrorsWrapper}>
            {errors.password && (
              <span className={styles.Error}>{errors.password.message}</span>
            )}
            {isError && (
              <span className={styles.Error}>
                Обновите временный пароль или проверьте логин
              </span>
            )}
          </div>
        </div>
        <input
          type="submit"
          className={styles.SubmitButton}
          value="Авторизоваться"
        />
        <div className={styles.LogoWrapper}>
          <DexSysLogo />
        </div>
      </form>
    </div>
  );
};

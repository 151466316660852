import jwt_decode from "jwt-decode";
import { getAccessToken } from "../../../api/utils/getAccessToken";

import { TokenType } from "../types";

export const getExpired = (): number | null => {
  const token = getAccessToken();
  try {
    const getToken: TokenType = jwt_decode(token || "");
    return getToken.exp;
  } catch {
    return null;
  }
};

import { infoDescriptionType } from "./types";

export const infoDescription: infoDescriptionType = {
  quiet: {
    title: "Тихий коворкинг 410",
    description:
      "Три изолированных рабочих места, отделены звукопоглощающими ширмами. Каждое рабочее место оборудовано стационарным компьютером и наушниками с  активным шумоподавлением.",
  },
  standart: {
    title: "Коворкинг 415",
    description:
      "Уютный кабинет, в котором много света. Шесть стандартных рабочих мест,оборудованных стационарными компьютерами. Есть 'стоя' — наклонное рабочее место для работы с ноутбуком",
  },
} as const;

import { SkeletonSizeType } from "../types";

export const getUserBookingsSkeletonSize = (
  clientWidth: number | null
): SkeletonSizeType => {
  if (clientWidth) {
    if (clientWidth > 970) {
      return { width: 750, heigth: 640 };
    }
    if (clientWidth <= 970 && clientWidth > 750) {
      return { width: clientWidth - 150, heigth: 640 };
    }
    if (clientWidth <= 750 && clientWidth > 380) {
      return { width: clientWidth - 100, heigth: 500 };
    }
    if (clientWidth <= 380) {
      return { width: clientWidth - 20, heigth: 500 };
    }
  }
  return { width: 0, heigth: 0 };
};

export const formatTime = (value: string | null) => {
  if (!!value) {
    const result = value?.split(":");
    if (result[0].length < 2) {
      result[0] = "0" + result[0];
    }
    return result.join(":");
  }
  return null;
};

import React, { useEffect, useState } from "react";
import cn from "classnames";

import { BookingMapEnum } from "../../types";
import { BookingMapQuietIcon } from "./icons";
import { infoDescription } from "../../constant";
import { BookingMapSkeleton } from "./BookingMapSkeleton";

import styles from "./styles.module.css";

interface BookingMapProps {
  type: BookingMapEnum;
}

export const BookingMap: React.FC<BookingMapProps> = ({ type }) => {
  const [iconType, setIconType] = useState<BookingMapEnum | null>(type);

  useEffect(() => {
    setIconType(null);
    setTimeout(() => setIconType(type), 500);
  }, [type]);

  // const bookingMapIcon =
  //   iconType === BookingMapEnum.standart
  //     ? BookingMapStandartIcon
  //     : BookingMapQuietIcon;

  {
    /* Временно убран по просьбе HR. Остается только тихий коворк */
  }
  const bookingMapIcon = BookingMapQuietIcon;

  return (
    <>
      {iconType ? (
        <div className={styles.Wrapper}>
          <img
            src={bookingMapIcon}
            alt="Карта для бронирования рабочего места"
            className={cn({
              [styles.ImgStandart]: type === BookingMapEnum.standart,
              [styles.ImgQuiet]: type === BookingMapEnum.quiet,
            })}
          />
        </div>
      ) : (
        <BookingMapSkeleton />
      )}
      <div className={styles.DescriptionWrapper}>
        <p className={styles.DescriptionTitle}>
          {type === BookingMapEnum.standart
            ? infoDescription.standart.title
            : infoDescription.quiet.title}
        </p>
        <p>
          {type === BookingMapEnum.standart
            ? infoDescription.standart.description
            : infoDescription.quiet.description}
        </p>
      </div>
    </>
  );
};

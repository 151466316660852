import React from "react";

import { DexSysLogoIcon } from "./icons";

import styles from "./styles.module.css";

export const DexSysLogo: React.FC = () => {
  return (
    <div className={styles.Wrapper}>
      <img
        src={DexSysLogoIcon}
        alt=""
        aria-hidden={true}
        width={210}
        height={65}
      />
    </div>
  );
};

import { REGEXP, ERROR_MESSAGES } from "./constants";

export const validateLogin = (value: string): boolean | string => {
  const validation = value.match(REGEXP.Login);
  if (value === "" || !value) {
    return ERROR_MESSAGES.EmptyField;
  }
  if (!validation) {
    return ERROR_MESSAGES.InvalidLogin;
  }

  return true;
};

export const validatePassword = (value: string): boolean | string => {
  const validation = value.match(REGEXP.Password);
  if (value === "" || !value) {
    return ERROR_MESSAGES.EmptyField;
  }
  if (!validation) {
    return ERROR_MESSAGES.InvalidPassword;
  }

  return true;
};

import { SkeletonSizeType } from "../../UserBookings/types";

export const getWorkPlacesTimeSkeletonSize = (
  clientWidth: number | null
): SkeletonSizeType => {
  if (clientWidth) {
    if (clientWidth > 830) {
      return { width: 750, heigth: 400 };
    }
    if (clientWidth <= 830 && clientWidth > 360) {
      return { width: clientWidth - 80, heigth: 500 };
    }
    if (clientWidth <= 360) {
      console.log(1);

      return { width: clientWidth - 20, heigth: 500 };
    }
  }
  return { width: 0, heigth: 0 };
};

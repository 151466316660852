import {
  WorkPlacesTimeDescriptionItem,
  WorkPlacesTimeDescriptionType,
  BookedListItem,
  BookedListResponseType,
  BookedListType,
} from "../types";

const isBooked = (value: number, bookedList: BookedListType) => {
  let booked: boolean = false;
  let user: string | null = null;
  bookedList.forEach((item: BookedListItem) => {
    if (item.timeFrom && item.timeTo) {
      if (item.timeFrom <= value && value <= item.timeTo) {
        booked = true;
        user = item.nickname;
      }
    }
  });
  return { booked, user };
};

const formatTime = (value: string) => {
  if (!!value) {
    const result = value?.split(":");
    result.pop();
    return Number(result.join("."));
  }
  return null;
};

export const getBookedsTime = (
  WorkPlacesTimeDescription: WorkPlacesTimeDescriptionType,
  bookedListResponse: BookedListResponseType | null
) => {
  if (bookedListResponse) {
    const bookedList: BookedListType = bookedListResponse.map((item) => {
      return {
        timeFrom: formatTime(item.interval.from),
        timeTo: formatTime(item.interval.to),
        nickname: item.user.nickname,
      };
    });

    if (!bookedList) {
      return [];
    }

    return WorkPlacesTimeDescription.map(
      (item: WorkPlacesTimeDescriptionItem) => {
        return { time: item.time, booked: isBooked(item.value, bookedList) };
      }
    );
  }
  return null;
};

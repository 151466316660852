import { WorkPlacesArrType, WorkPlacesTimeDescriptionType } from "./types";

export const WorkPlacesStandart: WorkPlacesArrType = [
  { id: "1" },
  { id: "2" },
  { id: "3" },
  { id: "4" },
  { id: "5" },
  { id: "6" },
  { id: "7" },
];

export const WorkPlacesQuiet: WorkPlacesArrType = [
  { id: "8" },
  { id: "9" },
  { id: "10" },
];

export const WorkPlacesTimeDescription: WorkPlacesTimeDescriptionType = [
  { time: "8:00", value: 8 },
  { time: "8:30", value: 8.5 },
  { time: "9:00", value: 9 },
  { time: "9:30", value: 9.5 },
  { time: "10:00", value: 10 },
  { time: "10:30", value: 10.5 },
  { time: "11:00", value: 11 },
  { time: "11:30", value: 11.5 },
  { time: "12:00", value: 12 },
  { time: "12:30", value: 12.5 },
  { time: "13:00", value: 13 },
  { time: "13:30", value: 13.5 },
  { time: "14:00", value: 14 },
  { time: "14:30", value: 14.5 },
  { time: "15:00", value: 15 },
  { time: "15:30", value: 15.5 },
  { time: "16:00", value: 16 },
  { time: "16:30", value: 16.5 },
  { time: "17:00", value: 17 },
  { time: "17:30", value: 17.5 },
  { time: "18:00", value: 18 },
  { time: "18:30", value: 18.5 },
  { time: "19:00", value: 19 },
  { time: "19:30", value: 19.5 },
  { time: "20:00", value: 20 },
  { time: "20:30", value: 20.5 },
  { time: "21:00", value: 21 },
  { time: "21:30", value: 21.5 },
  { time: "22:00", value: 22 },
  { time: "22:30", value: 22.5 },
  { time: "23:00", value: 23 },
];
